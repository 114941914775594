<template>
  <Layout navbarTransparent>
    <Header :image="current.procat_image" :header="current.procat_desc" :header_eng="current.procat_desc_en" />

    <div class="my-4"></div>

    <div class="position-relative" v-if="current.procat_name == 'Tax'"> 
      <div class="py-4 container">
        <div class="d-flex align-items-center justify-content-center">
          <div class="text-center position-relative py-3"> 
            <div v-if="currentLocale == 'IND'" >
              <h1 class="font-weight-bolder">Membuat Faktur dari Quotation dengan mudah dan cepat.</h1>
              <p>
                Secara otomatis, tagihan dibuat berdasarkan pesanan penjualan, pesanan pengiriman, kontrak, atau waktu dan material.
              </p>
              <div class="row align-items-center mt-3">
                <div class="col-lg-4">
                  <div class="d-flex justify-content-center"><img src="@/static/statistics (1) 1.png" /></div>
                  <p class="text-center mt-1">
                    Membuat faktur secara mudah berdasarkan penawaran yang dikirim kepada klien.
                  </p>
                </div>
                <div class="col-lg-4">
                  <div class="d-flex justify-content-center"><img src="@/static/calendar (5) 1.png" /></div>
                  <p class="text-center mt-1">
                    Kirim faktur dengan tampilan profesional secara langsung ke klien dengan sekali klik.
                  </p>
                </div>
                <div class="col-lg-4">
                  <div class="d-flex justify-content-center"><img src="@/static/document 1.png" /></div>
                  <p class="text-center mt-1">
                    Mengirim RfQ melalui email sebagai lampiran PDF atau cetak dan kirim RfQ melalui email secara otomatis.
                  </p>
                </div>
              </div>
            </div>
            <div v-else >
              <h1 class="font-weight-bolder">Create Invoice from Quotation easily and quickly.</h1>
              <p>
                Automatically, invoices are generated based on sales orders, delivery orders, contracts, or time and materials.
              </p>
              <div class="row align-items-center mt-3">
                <div class="col-lg-4">
                  <div class="d-flex justify-content-center"><img src="@/static/statistics (1) 1.png" /></div>
                  <p class="text-center mt-1">
                    Easily create invoices based on quotes sent to clients.
                  </p>
                </div>
                <div class="col-lg-4">
                  <div class="d-flex justify-content-center"><img src="@/static/calendar (5) 1.png" /></div>
                  <p class="text-center mt-1">
                    Send professional-looking invoices directly to clients with one click.
                  </p>
                </div>
                <div class="col-lg-4">
                  <div class="d-flex justify-content-center"><img src="@/static/document 1.png" /></div>
                  <p class="text-center mt-1">
                    Email RfQs as PDF attachments or print and email RfQs automatically.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-for="(data, idx) in list">
      <div :key="data.pl2_id">
        <comp-1 v-if="(idx + (current.procat_name == 'Tax - Finance' ? 2 : 1)) % 2 != 0" :content="data.pl2_content" :index="idx + (current.procat_name == 'Tax - Finance' ? 2 : 1)" />
        <comp-2 v-else :content="data.pl2_content" :index="idx + 2" />
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import Header from "./header.vue";
import Comp1 from "./components/layout2-comp1.vue";
import Comp2 from "./components/layout2-comp2.vue";

import store from "@/store";

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTabs,
  BTab,
  BEmbed,
} from "bootstrap-vue";

export default {
  components: {
    Header,
    Comp1,
    Comp2,

    Layout,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTabs,
    BTab,
    BEmbed,
  },
  mounted() {
    this.getNextDatas();
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT;
    },
  },
  watch: {
    currentLocale(val) {
      this.getNextDatas();
    },
  },
  methods: {
    async getNextDatas() {
      this.current = await store.dispatch("product_categories/getDataList", {
        id: this.$route.params.id,
      });
      this.list = await store.dispatch("product_categories/getDataLayout2", {
        id: this.$route.params.id,
        langcode: this.currentLocale,
      });
    },
  },
  data() {
    return {
      current: {},
      list: [],
    };
  },
};
</script>

<style>
.nav-tabs .nav-link.active {
  color: #ef6d22;
}
.nav-tabs .nav-link.active::after {
  background: #ef6d22 !important;
  height: 5px;
  border-radius: 3px;
}
</style>
