<template>
  <div class="position-relative">
          <img
            v-if="index != 1"
            src="@/static/assets/arrow-down.png"
            class="position-absolute"
            :style="`${
              index % 2 == 0 ? 'left' : 'right'
            }: 50px; top: -30px; max-width: 50px`"
          /> 
    <div class="py-4 container" style="max-width:1000px">
      <div class="d-flex align-items-center justify-content-center">
        <div class="text-center position-relative py-3">
          <img
            src="@/static/assets/Ellipse 24.png"
            class="position-absolute"
            :style="`z-index: -1; ${index % 2 == 0 ? 'right' : 'left'}: 10%; top: 0`"
          />
          <div :id="`content-${index}`" v-html="content">
            <h1>What is Lorem Ipsum?</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled it to
              make a type specimen book. It has survived not only five centuries, but also
              the leap into electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    // document.getElementById("content-" + this.index).innerHTML = this.content;
  },
};
</script>
