<template>
  <div class="d-flex align-items-center justify-content-center w-100">
    <div class="text-center position-relative my-3 w-100">
      <img
        src="@/static/assets/Group 683.png"
        class="w-100 position-absolute"
        style="height: 400px; left: 0px; top: 0; z-index: -1"
      />


      <img
        src="@/static/assets/arrow-down.png"
        class="position-absolute"
        :style="`${index % 2 != 0 ? 'left' : 'right'}: 50px; top: -30px; max-width: 50px`"
      />
      <div class="container d-flex pt-5" style="min-height: 400px;max-width:1000px">
        <div class="my-auto mx-auto pt-5" style="" :id="`content-${index}`" v-html="content">
          <h1>What is Lorem Ipsum?</h1>
          <p>
            What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five
            centuries, but also the leap into electronic typesetting, remaining
            essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with
            desktop publishing software like Aldus PageMaker including versions of Lorem
            Ipsum.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    // document.getElementById("content-"+this.index).innerHTML = this.content;
  },
};
</script>
